import React from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import arena from "./images/arena.svg";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Readme from "./README.md"
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'


const useStyles = theme => ({
    app:{
        width:"100%",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        margin:0,
    },
    titleBox:{
        width:"100%",
        overflow:"hidden",

    },
    titleImage:{
        width:"100%",
    },
    home:{
        padding:theme.spacing(1)
    },
    arena:{
        width: theme.spacing(20),
        selfAlign: "center"
    }

  });

class Setups extends React.Component {

  
    constructor(props) {
      super(props);
      this.state = {markdown:""}
    }

    componentDidMount(){
        fetch(Readme)
        .then(r => r.text())
        .then(text => {
            this.setState({markdown: text});
        });
    }

    render(){
        const { classes } = this.props;
        return (
            <div>

                <Grid container spacing={2} className={classes.app}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                    <ReactMarkdown remarkPlugins={[gfm]} children={this.state.markdown} />

                        {/* <div className={classes.home}>
                            <h1>
                                How the competition works
                            </h1>
                            <p>
                               The GPPC 2021 will continuesly accept submissions
                                from participants and automaticly evaluate participants' implementation
                                upon submission.
                                <br/><br/>
                                This means participants can submit whatever times they want before the final 
                                deadline!
                                <br/><br/>
                                The competition evaluates participants' implementation on a set of metrics, which 
                                including computation time, optimality, preprocessing, RAM usage and so on.
                                
                            </p>
                            <h1>
                                Competition Instances
                            </h1>
                            <p>
                                The competition compares participants' implementation on a variety of benchmark domains from <a href="https://movingai.com">MovingAI.com</a>. These 
                                domains include both real world problems and synthetic problems and are widely adopted by the path planning community. 
                                <br/><br/>
                                Besides the classical domains, the competition newly introduces new domains from recent real-time strategy game to the competition.  
                                <br/><br/>
                                The competition evenly samples 20,000 problems on a vareity of maps from each of the domain based on the path length of the problem.

                            </p> 
                            <h1>
                                Problems and Rules
                            </h1>
                            <p>
                                The competition gives a set of problem scenarios to participant's submission one by one, and each scenario comes with a problem map.
                                <br/><br/>
                                For algorithms require preprocessing, each submission is given 10 hours preprocessing time to preprocess all maps invloved in the problem before the start of the benchmark.
                                <br/><br/>
                                In each run, participant's need to solve all problem entries in a single scenario file.
                                <br/><br/>
                                Here's an example on the problem you will solve:
                                <br/><br/>
                            </p>
                            <img className={classes.arena} src={arena}></img>
                            <Table className={classes.table} aria-label="Scenario">
                                <TableHead>
                                <TableRow>
                                    <TableCell align="left">Bucket</TableCell>
                                    <TableCell align="left">map</TableCell>
                                    <TableCell align="left">map width</TableCell>
                                    <TableCell align="left">map height</TableCell>
                                    <TableCell align="left">start x</TableCell>
                                    <TableCell align="left">start y</TableCell>
                                    <TableCell align="left">goal x</TableCell>
                                    <TableCell align="left">goal y</TableCell>
                                    <TableCell align="left">optimal length</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                <TableRow>
                                    <TableCell alight="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                    <TableCell align="left"></TableCell>
                                </TableRow>
                                </TableBody>
                            </Table>

                            <h1>
                                Starter kit
                            </h1>
                            <p>
                               We will provide a starter kit with validation tools to help participants easily create submissions when the competition is ready.
                            </p> 
                        </div> */}
                    </Grid>
                
                </Grid>
            </div>
        
        )
    }
}

export default withStyles(useStyles) (Setups);