import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LoginButton from "./LoginButton";
import Typography from '@material-ui/core/Typography';
import { Paper,Grid } from "@material-ui/core";
import Input from '@material-ui/core/Input';

const config = require("./config")

const useStyles = makeStyles((theme) => ({
    
    admin:{
        width:"100%",
        height:"100%",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(2),
    },
    content:{
        width:"100%",
        height:"100%",
        padding:theme.spacing(2)
    },
    history_board:{
        width:"100%",
        height:theme.spacing(20),
        padding:theme.spacing(2),
        overflow:"scroll"

    }
  }));

function evaluate(user_name, account_name, token, appendHistory){

    appendHistory(`Evaluate ${user_name} ${account_name} send.`)
        fetch(`/api/evaluate?username=${user_name}&account_name=${account_name}`, {
            method: 'GET',
            headers:{
                "x-access-token": token
            }
        })
        .then((res)=>res.json())
        .then((body)=>{
            appendHistory(`Evaluate ${user_name} ${account_name} response: ${JSON.stringify(body)}`)
        })
        .catch((err)=>{
            appendHistory(`Evaluate ${user_name} ${account_name} response: ${JSON.stringify(err)}`)
        })
    
}

function reset_evaluator(user_name, account_name, token, appendHistory){
    appendHistory(`Reset evaluator ${user_name} ${account_name} send.`)

    fetch(`/api/reset_evaluator?username=${user_name}&account_name=${account_name}`, {
        method: 'GET',
        headers:{
            "x-access-token": token
        }
    })
    .then((res)=>res.json())
    .then((body)=>{
        appendHistory(`Reset evaluator ${user_name} ${account_name} response: ${JSON.stringify(body)}`)
    })
    .catch((err)=>{
        appendHistory(`Reset evaluator ${user_name} ${account_name} response: ${JSON.stringify(err)}`)
    })

}

function remove_best(user_name, account_name, token, appendHistory){
    appendHistory(`Remove best ${user_name} ${account_name} send.`)

    fetch(`/api/remove_best?username=${user_name}&account_name=${account_name}`, {
        method: 'GET',
        headers:{
            "x-access-token": token
        }
    })
    .then((res)=>res.json())
    .then((body)=>{
        appendHistory(`Remove best ${user_name} ${account_name} response: ${JSON.stringify(body)}.`)
    })
    .catch((err)=>{
        appendHistory(`Remove best ${user_name} ${account_name} response: ${JSON.stringify(err)}.`)
    })

}

var account_name="";
var username = "";
export default function Admin(props) {
    
    const classes = useStyles();
    const [history,setHistory] = useState([]);

    let appendHistory = (content) =>{
        setHistory(history.concat([` ${new Date().toLocaleTimeString()}: ${content}`]))
    }


    return (
    <Grid container justifyContent="center" spacing={2} className={classes.admin}>

        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper className={classes.history_board}>
                {history.map((item, key)=>{
                    return <p id={"history"+key} >{item}</p>
                })}
                
            </Paper>
        </Grid>
        
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper className={classes.content}>
                <Typography>
                    Run evaluation for: <br></br>
                    <Input id="username" onChange={(e)=>{username = e.target.value}} placeholder="Username"  />
                    <Input id="account_name" onChange={(e)=>{account_name = e.target.value}} placeholder="Account Name"  />
                    <Button  size="small"  variant="contained" onClick={()=>{evaluate(username,account_name,props.login_data.accessToken, appendHistory)}}>Evaluate</Button>
                    <Button  size="small"  variant="contained" onClick={()=>{reset_evaluator(username,account_name,props.login_data.accessToken, appendHistory)}}>Reset Evaluator</Button>
                    <Button  size="small"  variant="contained" onClick={()=>{remove_best(username,account_name,props.login_data.accessToken, appendHistory)}}>Remove Best</Button>

                </Typography>
            </Paper>
        </Grid>
    </Grid>
    )
    
    
}