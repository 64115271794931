import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  });

const headCells = [
    { id: 'total_time', numeric: true, disablePadding: false, label: 'Total Time (s)' },
    { id: 'avg_time', numeric: true, disablePadding: false, label: 'Average Time/Path (ms)' },
    { id: 'avg_start_time', numeric: true, disablePadding: false, label: 'Average Start Time (ms)(f20m)' },
    { id: 'max_time_per_segment', numeric: true, disablePadding: false, label: 'Max Time (ms) (per segment)' },
    { id: 'avg_path_length', numeric: true, disablePadding: false, label: 'Average Path Length' },
    { id: 'avg_subopt', numeric: true, disablePadding: false, label: 'Average Suboptimality' },
    { id: 'RAM_changes', numeric: true, disablePadding: false, label: 'Max RAM Usage' },
    { id: 'storage', numeric: true, disablePadding: false, label: 'Max Storage' },
    { id: 'preprocess_time', numeric: true, disablePadding: false, label: 'Precomputation Time (total min)' },
  ];

function Summary(props){
    const {data} = props;
    return(
        <Paper>
        <Table>
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                    >
                        {headCell.label}
                    </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                    >
                        {data[headCell.id]}
                    </TableCell>
                    ))}
                </TableRow>
            </TableBody>
        </Table>
        </Paper>
        
    )
}
function Row(props) {
    const { value,dt } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
            <TableCell>
                <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
            <TableCell align="center">{dt.toLocaleString()}</TableCell>
            <TableCell align="center">{value.submission_status}</TableCell>
            <TableCell align="center">
                <p>
                Precomputation/Validation:{Math.round(value.precomputed_instances/value.total_instances*100)}%
                </p>
                <p>
                Evaluation:{Math.round(value.evaluated_instances/value.total_instances*100)}%
                </p>
                </TableCell>
            <TableCell align="left">{value.message}</TableCell>
            <TableCell align="center">{value.repo_head}</TableCell>

        </TableRow>
        <TableRow>
            <TableCell></TableCell>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1}>
                    <Typography variant="h6" gutterBottom component="div">
                    Summary
                    </Typography>
                    <Typography variant="body1" gutterBottom component="div">
                    {value.summary == undefined ? "":<Summary data={value.summary}></Summary>}
                    </Typography>

                    <Typography variant="h6" gutterBottom component="div">
                    Progress Logs
                    </Typography>
                    <Typography variant="body1" gutterBottom component="div">
                        <Paper>
                        {value.progress_log != undefined ? value.progress_log.map((item,key)=>{return (<div key={key}>{item}</div>)}) : ""}
                        </Paper>
                    </Typography>
                    {value.private_log == undefined?"":
                        <Typography variant="h6" gutterBottom component="div">
                            Private Logs
                        </Typography>
                        }
                    {value.private_log == undefined?"":
                        <Typography variant="body1" gutterBottom component="div">
                            <Paper>
                            {value.private_log.map((item,key)=>{return (<div key={key}>{item}</div>)})}
                            </Paper>
                        </Typography>
                        }

                    
                </Box>
                </Collapse>
            </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }
  

export default function MySubTable(props) {
    var { all_subs } = props;

    
    

    return (
        <Table>
        <TableHead>
            <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">Submission Time</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Progress</TableCell>
                <TableCell align="center">Error Message</TableCell>
                <TableCell align="center">Commit Info</TableCell>


            </TableRow>
        </TableHead>
        <TableBody>
        {all_subs.map((value,index)=>{
            var dt = new Date(value.date);

            return (
                <Row key={index} value={value} dt={dt} />
            )
        })}
        </TableBody>
    </Table>
    );
  }