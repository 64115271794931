import React from 'react';
import './App.css';
import { createTheme,ThemeProvider,withStyles } from '@material-ui/core/styles';
import { Paper,Grid } from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';

import {
  Switch,
  Route,
} from "react-router-dom";


import HomeIcon from '@material-ui/icons/Home'
import AssignmentIcon from '@material-ui/icons/Assignment'
import BorderColorIcon from '@material-ui/icons/BorderColor';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SettingsIcon from '@material-ui/icons/Settings';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';

import GridOn from '@material-ui/icons/GridOn';
import Timeline from '@material-ui/icons/Timeline';
import Info from '@material-ui/icons/Info';

import Competition from './competition';
import Setups from './setups';
import ExternalPage from './external';
import LoginModule from "./LoginModule";
import Admin from './Admin';
import Setting from './Setting';



let theme = createTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#f1f1f1',
      dark: '#006db3',
    },
    secondary:{
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
    }
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        // marginLeft: theme.spacing(1),
        // height:10
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        // backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        marginLeft: theme.spacing(1),
        minWidth: theme.spacing(2),
        padding: 0,
        // [theme.breakpoints.up('md')]: {
        //   padding: 0,
        //   minWidth: theme.spacing(10),
        // },
      },
    },
    MuiIconButton: {
      root: {
        // padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiGrid:{
      root:{
        margin:0
      }
    }
  },
};

const useStyles ={
  root: {
    backgroundColor:theme.palette.primary.main,
    minWidth:"100%",
    minHeight:"100.4vh",
    flexGrow: 1,
  },
  app:{
    width:"100%",
    paddingLeft:theme.spacing(2),
    paddingRight:theme.spacing(2),
    paddingBottom:theme.spacing(2),
    margin:0,
    position:"fixed",
    top:0
  },
  bar_grid:{
    paddingBottom:0,
  },
  welcome:{
    position:"fixed",
    width:"100%",
    fontSize:25,
    backgroundColor:"black"
  },
  tab:{
    minWidth: theme.spacing(8),
    marginRight: theme.spacing(1)
  },
  tab_div:{
    width:"100%",
    justifyContents:"space-between"
  },
  content:{
    paddingTop:theme.spacing(8)
  }
};





class App extends React.Component {

  
  constructor(props) {
    super(props);
    var tab=0;
    // if (this.props.location.pathname == "/setups"){
    //   tab = 1;
    // }
    // else 
    this.pages=["/grid","/anyangle","/faq","/about","/setting","/admin"]

    for(let i=0;i<this.pages.length;i++){
      if(this.props.location.pathname.includes(this.pages[i])){
        tab = i+1;
        break;
      }
    }

    
    this.state = {    
      tab: tab,
      loading:false,
      login_data :undefined,
      current_account:undefined,
    };
  }

  nextPath(path) {
    this.props.history.push(path);
  }
  
  handleChangeTab = (event, newValue) => {
    this.setState({tab: newValue},() => {
      if (newValue === 0){
        this.nextPath('/')
      }
      else{
        this.nextPath(this.pages[newValue-1])
      }
    } );
  };

  set_login = (data)=>{

    localStorage.setItem('login_data', JSON.stringify(data));

    this.setState({login_data:data});
  }

  set_logout = ()=>{
    localStorage.setItem('grid_account', null);
    localStorage.setItem('anyangle_account', null);
    localStorage.setItem('login_data', null);
    this.setState({login_data:undefined});
  } 

  login_github = async (code,state) => {
    this.setState({loading:true},()=>{
        fetch('/api/auth/github_signin', {
            method: 'POST',
            headers: {
            "Content-type": "application/json",
            },
            body: JSON.stringify({ code: code, state: state }),
        })
        .then((res)=>res.json())
        .then((body)=>{
            if (body.success){
                this.set_login(body)
            }
            else(
                this.setState({loading:false})
            )
        })
        
    })
  };

  componentDidMount(){
    const url = window.location.href;
    const hasCode = url.includes("?code=");

    var login_data = JSON.parse(localStorage.getItem('login_data'));
    if (hasCode){
        const data = url.split("?code=")[1].split("&state=");
        const code = data[0];
        const state = data[1];
        this.login_github(code,state);
        this.nextPath('/');
    }
    else if(login_data == null && !hasCode){
      this.setState({login:false});
    }
    else if (login_data != null ){
        this.set_login(login_data);
    }
  }




  render() {
    const { classes } = this.props;
    const styles = {
      tab: {
          color: '#ffffff'
      }
    }
    return (
      <ThemeProvider theme={theme} >
        <div className={classes.root}>
          <AppBar className={classes.welcome} position="static">
          <Grid  container alignItems="center" spacing={0}>
            <Grid className={classes.bar_grid} item xs={12} sm={10} md={10} lg={10}>
              <Tabs
              className={classes.tab_div} 
              // variant="fullWidth"
              value={this.state.tab}
              indicatorColor="secondary"
              textColor="secondary"
              onChange={this.handleChangeTab.bind(this)}
              aria-label="Navi tab"
              justifyContents="space-between"
              >
                <Tab className={classes.tab} style={styles.tab}  icon={<HomeIcon />} label="Home" />
                {/* <Tab className={classes.tab} style={styles.tab} icon={<AssignmentIcon />} label="Setups" /> */}
                <Tab className={classes.tab} style={styles.tab} icon={<GridOn />} label="Classic" />
                <Tab className={classes.tab} style={styles.tab} icon={<Timeline />} label="Anyangle" />
                <Tab className={classes.tab} style={styles.tab}  icon={<LiveHelpIcon />} label="FAQ" />
                <Tab className={classes.tab} style={styles.tab}  icon={<Info />} label="About Us" />
                {this.state.login_data!==undefined?<Tab className={classes.tab} style={styles.tab} icon={<SettingsIcon />} label="Account" />:""}

                {this.state.login_data!==undefined && this.state.login_data.roles.includes("ROLE_ADMIN")?<Tab className={classes.tab} style={styles.tab} icon={<SupervisorAccountIcon />} label="Manage" />:""}
              </Tabs>
            </Grid>
            <Grid item xs={12} sm={2} md={2} lg={2}>
              <LoginModule set_logout={this.set_logout.bind(this)} login_data={this.state.login_data} ></LoginModule>
            </Grid>
          </Grid>
          </AppBar>
          <div className={classes.content}>
            <Switch>
              {/* <Route path="/setups" component={Setups}></Route> */}
              <Route path="/grid" render={()=>(<Competition track={"GRID4"} tab={this.state.tab} set_logout={this.set_logout.bind(this)} login_data={this.state.login_data} />)}></Route>
              <Route path="/anyangle" render={()=>(<Competition track={"ANYANGLE"} tab={this.state.tab}  set_logout={this.set_logout.bind(this)} login_data={this.state.login_data} />)}></Route>
              <Route path="/about" render={()=>(<ExternalPage page={"about.html"} tab={this.state.tab}/>)}  key={1}></Route>
              <Route path="/faq" render={()=>(<ExternalPage page={"faq.html"} tab={this.state.tab}/>)}  key={3}></Route>
              <Route path="/setting" render={()=>(<Setting tab={this.state.tab} set_login_data={(data)=>{this.set_login(data)}}  login_data={this.state.login_data} />)}></Route>
              <Route path="/admin" render={()=>(<Admin tab={this.state.tab}  login_data={this.state.login_data} />)}></Route>
              <Route path="/" render={()=>(<ExternalPage page={"index.html"} tab={this.state.tab}/>)} key={2}></Route>
            </Switch>
          </div>
        </div>
      </ThemeProvider>


    );
  }
}



export default withStyles(useStyles,{ withTheme: true }) (App);
