import React from "react";
import { Grid, Paper} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import banner from "./images/banner.png"
import CircularProgress from '@material-ui/core/CircularProgress';


const useStyles = theme => ({
    app:{
        width:"100%",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        margin:0,
    },
    titleBox:{
        width:"100%",
        overflow:"hidden",
        textAlign:"center"

    },
    titleImage:{
        width:"100%",
    },
    landingPaper:{

        maxWidth:"1350px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: theme.spacing(3),

        padding: theme.spacing(2)
    },
    loading:{
        margin:"auto",
    },
    home:{
        padding:theme.spacing(2)
    }

  });

class ExternalPage extends React.Component {

  
    constructor(props) {
        super(props);
        this.state = {
          landing_page: undefined,
          page: this.props.page
        }
        this.page_load_time = undefined;
    }

    componentDidMount(){
        if (this.state.landing_page===undefined ){
            fetch(`/landing_page/${this.props.page}`, {
                method: 'GET',
            })
            .then(res =>res.text())
            .then(res => {
                this.page_load_time = new Date();
                this.setState({landing_page: res});
            })
            .catch(err => {
                console.error(err);
            });
        }
    }

    render(){
        const { classes } = this.props;
        return (
            <div>
                {this.state.landing_page===undefined?
                <div className={classes.loading}>
                    <CircularProgress />
                </div>
                :
                <Grid container spacing={2} className={classes.app}>
                    <Paper className={classes.landingPaper}>
                    {this.state.landing_page === undefined ? "": <div dangerouslySetInnerHTML={{ __html: this.state.landing_page }} /> }
                    </Paper>
                </Grid>
                }
                
            </div>
        
        )
    }
}

export default withStyles(useStyles) (ExternalPage);