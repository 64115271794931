import React, { useState,useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';

import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import { Select,FormControl,InputLabel,MenuItem} from '@material-ui/core';



// function createData(rank,user, score, done,runtime, sub_date) {
//   return { rank, user, score, done,runtime, sub_date  };
// }

const useStyles = makeStyles((theme) => ({
  root: {
    width:"100%"
    // marginRight: theme.spacing(2),
    // marginTop: theme.spacing(2),
    // marginBottom: theme.spacing(2),
    // marginLeft: theme.spacing(2),


  },
  paper: {
    padding: theme.spacing(4),
    // margin: theme.spacing(4),
    // maxHeight:"70vh"


  },
  table: {

  },
  headText:{
    verticalAlign:"top"
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Challenger', description:'The name of the algorithm.'},
  { id: 'total_time', numeric: true, disablePadding: false, label: 'Total Time (s)' , description:'This is the total time to find the solution to all problems.'},
  { id: 'avg_time', numeric: true, disablePadding: false, label: 'Average Time per Path (ns)' , description:'This is the average time in nanoseconds to find a single path.'},
  { id: 'avg_start_time', numeric: true, disablePadding: false, label: 'Average Start Time (ns) ' , description:'This is the average time in nanoseconds to find the first 20 steps of a path. This measures how quickly a path is available to follow, which is important in real-time applications such as games or robotics.'},
  { id: 'max_time_per_segment', numeric: true, disablePadding: false, label: 'Average Max Time per Segment (ns)' , description:'This is the average of the maximum time required to produce any individual segment (any part of a start-to-target path, for example, a single action, a set of actions, or the complete path.). This measures the worst-case real-time performance.'},
  { id: 'avg_path_length', numeric: true, disablePadding: false, label: 'Average Path Length' , description:'This is the average length of a returned path. If an entry is optimal on long paths and suboptimal on short paths this will be close to the average length, since most of the length comes from the longest paths.'},
  { id: 'avg_subopt', numeric: true, disablePadding: false, label: 'Average Suboptimality' , description:'This is the average suboptimality of each path. If an entry is optimal on long paths and highly sub-optimal on short paths this measure will be large since most paths are short paths.'},
  { id: 'max_subopt', numeric: true, disablePadding: false, label: 'Maximum Suboptimality' , description:'This is the maximum suboptimality of any path. A path is considered suboptimal if its cost differs from optimal by more than 1e-4.'},
  // { id: 'optimality', numeric: true, disablePadding: false, label: 'Optimal / Suboptimal' , description:'This shows wether the solver is an optimal solver (finds optimal solutions for all problems), or suboptimal solver (finds suboptimal solutions for at least one problem).'},
  { id: 'RAM_after', numeric: true, disablePadding: false, label: 'Max RAM Usage (MB)' , description:'The maximum memory usage in MB after solving all problem instances of any map.'},
  { id: 'storage', numeric: true, disablePadding: false, label: 'Total Storage (MB)' , description:'This is the disk space used for all the precomputed storage.'},
  { id: 'preprocess_time', numeric: true, disablePadding: false, label: 'Precomputation Time (min)' , description:'This is the time (in minutes) required for the full pre-computation. Entries that perform parallel pre-computation are marked with a † in the results table in the next section.'},
  { id: 'date', numeric: false, disablePadding: false, label: 'Submission Date' , description:'Submission time on your local time'},
];

const defaultDominanceKey = new Set(["total_time","avg_time","avg_start_time","max_time_per_segment","avg_subopt","preprocess_time"]);

const eps = 0.00001;

function dominate (d1, d2, undomiKeys){
  if (undomiKeys.size==0){
    return false;
  }
  let allequal = true;
  let dominate = true;
  undomiKeys.forEach((item)=>{
    if(d1[item] - d2[item] > eps){
      dominate = false;
    }
    if (Math.abs(d1[item] - d2[item]) > eps){
      allequal = false;
    }
  })
  return dominate && !allequal
}

function filterDominatedEntries(rows, undomiKeys){
  let filteredRows = new Set();
  for(let i = 0; i<rows.length;i++){
    let not_dominated = true;
    let list =Array.from(filteredRows);
    for (let j = 0; j<list.length;j++){
      // console.log(rows[i], list[j],dominate(rows[i], list[j], undomiKeys), dominate( list[j], rows[i], undomiKeys));
      if (dominate(rows[i], list[j], undomiKeys)){
        filteredRows.delete(list[j]);
      }
      else if (dominate( list[j], rows[i], undomiKeys)){
        not_dominated = false;
      }
    }
    if (not_dominated){
      filteredRows.add(rows[i]);
    }

  }

  return Array.from(filteredRows);
}

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, undomiOnly,undomiKeys, setUndomiKeys } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const setUndomi = (id)=>{
    undomiKeys.has(id)?undomiKeys.delete(id):undomiKeys.add(id); 
    setUndomiKeys(new Set(undomiKeys));
  }

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'right'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.headText}
      
          >
            {undomiOnly && headCell.id!="name" && headCell.id!="date" ? <Checkbox checked={undomiKeys.has(headCell.id)} onClick={()=>{setUndomi(headCell.id)}}></Checkbox>: (undomiOnly?<div><br></br><br></br></div>:"")}

            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <Tooltip title={headCell.description}>
                <span>
                  {headCell.label}
                </span>
              </Tooltip>

              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    verticalAlign:"top"
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 50%',

  },
  undominated:{
    // minWidth:theme.spacing(10),
    textAlign:"center"
  },
  formControl: {
    margin: theme.spacing(1),
  }

}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected,optimalOnly, setOptimalOnly,
     suboptimalOnly,setSuboptimalOnly,
      undomiOnly, setUndomiOnly,
      preprocessingFilter, setPreprocessingFilter
    } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          LEADERBOARD
        </Typography>
      )}

      {/* a select for undominated only */}
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Dominance Filter</InputLabel>
        <Select className={classes.undominated}
          value={undomiOnly?1:0}
          onChange={(event)=>{
            event.target.value==1?
              setUndomiOnly(true):
              setUndomiOnly(false)}
          }>
          <MenuItem value={0}>
            <Typography variant='body2' >None</Typography>
            </MenuItem>
          <MenuItem value={1}><Typography variant='body2'>Undominated Only</Typography></MenuItem>
        </Select>
      </FormControl>
      
      {/* a select for optimal only/suboptimal only  */}
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Optimality Filter</InputLabel>
        <Select className={classes.undominated} 
          value={optimalOnly?1:(suboptimalOnly?2:0)}
          onChange={(event)=>{
            switch(event.target.value){
              case 1:
                setOptimalOnly(true);
                setSuboptimalOnly(false);
                break;
              case 2:
                setOptimalOnly(false);
                setSuboptimalOnly(true);
                break;
              default:
                setOptimalOnly(false);
                setSuboptimalOnly(false);
                break;
            }
          }}>
          <MenuItem value={0}><Typography variant='body2'>None</Typography></MenuItem>
          <MenuItem value={1}><Typography variant='body2'>Optimal Only</Typography></MenuItem>
          <MenuItem value={2}><Typography variant='body2'>Suboptimal Only</Typography></MenuItem>
        </Select>
      </FormControl>
      
      

      {/* a select for preprocessing filter: 0: None, 1: With Preprocessing, 2:  Without Preprocessing*/}
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-label">Preprocessing Filter</InputLabel>
        <Select className={classes.undominated}
          value={preprocessingFilter}
          onChange={(event)=>{
            setPreprocessingFilter(event.target.value)
          }
          }>
          <MenuItem value={0}>
            <Typography variant='body2' >None</Typography>
            </MenuItem>
          <MenuItem value={1}><Typography variant='body2'>With Preprocessing</Typography></MenuItem>
          <MenuItem value={2}><Typography variant='body2'>Without Preprocessing</Typography></MenuItem>
        </Select>
      </FormControl>


      


    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const refreshLeader = (track,callback)=>{
  fetch(`/api/leader_board?track=${track}`, {method: 'GET'})
  .then(res => res.json())
  .then(data => {
    callback(data.leader_board);
  })
  .catch(err => console.error(err));
}

function parse_data(data, optimalOnly,suboptimalOnly, preprocessingFilter){
  let copy = JSON.parse(JSON.stringify(data))
  let rows = []
  for (var i in copy){
    let optimal = copy[i]["optimal_num"] == copy[i]["total"];
    if (copy[i]["optimal_num"] ==undefined)
    {
      optimal = copy[i]["avg_subopt"] ==1;
    }
    if ((optimalOnly && !optimal) || (suboptimalOnly && optimal))
      continue;
    if (preprocessingFilter == 1 && copy[i]["preprocess_time"] == 0)
      continue;
    if (preprocessingFilter == 2 && copy[i]["preprocess_time"] != 0)
      continue;

    var dt = new Date(copy[i].sub_date);
    copy[i].date= dt.toLocaleString();
    copy[i]["avg_path_length"] = Math.round(copy[i]["avg_path_length"]*(10**2))/(10**2)
    copy[i]["avg_subopt"] = Math.round(copy[i]["avg_subopt"]*(10**4))/(10**4)
    if (copy[i]["max_subopt"] == undefined)
      copy[i]["max_subopt"] = "Awaiting"
    else
      copy[i]["max_subopt"] = Math.round(copy[i]["max_subopt"]*(10**4))/(10**4)
    copy[i]["storage"] = Math.round(copy[i]["storage"]/1024)
    copy[i]["preprocess_time"] = Math.round((copy[i]["preprocess_time"]/1000/60))
    copy[i]["total_time"] = Math.round((copy[i]["total_time"]/(10**9))*(10**3))/(10**3)
    if (copy[i]["RAM_after"] == undefined)
      copy[i]["RAM_after"] = "Awaiting"
    else
      copy[i]["RAM_after"] = Math.ceil((copy[i]["RAM_after"]/1024))
    copy[i]["optimality"] = optimal? "Optimal" : "Sub-optimal"

    rows.push(copy[i])
  }
  return rows
}

export default function LeaderBoard(props) {
  const classes = useStyles();
  const track = props.children;
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('name');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [data, setData] = useState([]);

  const [undomiKeys, setUndomiKeys] = useState(defaultDominanceKey);
  const [undomiOnly, setUndomiOnly] = useState(false);
  const [optimalOnly, setOptimalOnly] = useState(false);
  const [suboptimalOnly, setSuboptimalOnly] = useState(false);
  const [preprocessingFilter, setPreprocessingFilter] = useState(0);


  useEffect(() => {
    refreshLeader(track,(data)=>{
      setData(data)
    });

    // const interval = setInterval(() => {
    //   refreshLeader(track,(data)=>{
    //     setData(data)
    //   })
    // }, 30000);
    // return () => clearInterval(interval);
  }, []);

  var rows = parse_data(data, optimalOnly, suboptimalOnly, preprocessingFilter);

  if (undomiOnly){
    rows = filterDominatedEntries(rows,undomiKeys);
  }
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
            <EnhancedTableToolbar 
              numSelected={selected.length} 
              optimalOnly={optimalOnly}
              setOptimalOnly={setOptimalOnly}
              suboptimalOnly={suboptimalOnly}
              setSuboptimalOnly={setSuboptimalOnly}
              undomiOnly={undomiOnly} 
              setUndomiOnly={setUndomiOnly}
              preprocessingFilter={preprocessingFilter}
              setPreprocessingFilter={setPreprocessingFilter}
            />
            <TableContainer>

            <Table
                className={classes.table}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
                aria-label="enhanced table"
            >
               
                <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                undomiKeys={undomiKeys}
                undomiOnly={undomiOnly}
                setUndomiKeys={setUndomiKeys}
                />
                <TableBody>
                {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, name) => {
                    const isItemSelected = isSelected(name);
                    const labelId = `enhanced-table-checkbox-${name}`;

                    return (
                        <TableRow
                        hover
                        onClick={(event) => handleClick(event, name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={name}
                        selected={isItemSelected}
                        >
                        {headCells.map((item,item_index)=>{
                          let value = row[item.id]
                          if (item.numeric){
                            // value = numberWithCommas(value);
                            value = Intl.NumberFormat().format(value);
                          }
                          return (<TableCell key={item_index} align="right">
                            {item.id === "max_subopt"? 
                            <Tooltip title={`Num of Suboptimal Entries: ${(row.total - row.optimal_num).toLocaleString()}`}><span>{value}</span></Tooltip>
                            :
                              item.id === "name"?
                              <Tooltip title={
                                `${row.team_name?"Team name: "+row.team_name+". ":""}
                                ${row.affiliation?"Affiliation: "+row.affiliation+". ":""}
                                ${row.description?"Description: "+row.description+". ":""}`

                              }><span>{value}</span></Tooltip>
                              :
                              <span>{value}</span>
                            }
                          </TableCell>)
                        })}

                        </TableRow>
                    );
                    })}
                {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 13 : 33) * emptyRows }}>
                    <TableCell colSpan={6} />
                    </TableRow>
                )}
                </TableBody>
            </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[10,20,30]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
      </Paper>
    </div>
  );
}
