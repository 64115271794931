import React, {useState, useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LoginButton from "./LoginButton";
import Typography from '@material-ui/core/Typography';
import { Paper,Grid, Table, TableRow, TableCell, TableBody } from "@material-ui/core";
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import { TextField } from '@material-ui/core';


const config = require("./config")

const useStyles = makeStyles((theme) => ({
    
    admin:{
        width:"100%",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        margin:0,
        paddingTop: theme.spacing(2),
    },
    header:{
        width:"100%"
    },
    content:{
        // width:"100%",
        // height:"100%",
        padding:theme.spacing(2)
    },
    Liscense:{
        // width:"100%",
        // height:theme.spacing(20),
        paddingLeft:theme.spacing(2),
        paddingRight:theme.spacing(2),
        paddingTop:theme.spacing(1),
        paddingBottom:theme.spacing(1),

    },
    cell: {
        borderBottom: "none",
        textAlign: "left",
        width:"50%"
      },
    description:{
        width:"100%",
        marginTop:theme.spacing(1),
    },
    table:{
    }
  }));

export default function Setting(props) {
    
    const classes = useStyles();
    const [metaData, setMetaData] = useState(props.login_data === undefined? {}:props.login_data.meta_data);
    const [disable_save, setDisableSave] = useState(true);

    useEffect(() => {
        if (props.login_data!= undefined)
            setMetaData(props.login_data.meta_data);
    }, [props.login_data])

    //monitor metaData changes, if metaData.license is false, handleChange("license","MIT")
    useEffect(() => {
        if (!metaData.license || metaData.license === 
            "Please Select"){
            handleChange("license","MIT");
        }
    }, [metaData])

    var handleChange = (key,value)=>{
        let data = metaData;
        data[key] = value;
        setMetaData({...metaData,key:value});
    }

    var saveChanges = ()=>{
        fetch('/api/save_meta_data', {
            method: 'POST',
            headers: {
            "x-access-token": props.login_data.accessToken,
            "Content-type": "application/json",
            },
            body: JSON.stringify({ 
                meta_data: metaData,
             }),
        })
        .then((res)=>res.json())
        .then((body)=>{
            if (body.success){
                let login_data = props.login_data;
                login_data.meta_data = metaData;
                props.set_login_data(login_data);
            }
        })

    }

    if (!props.login_data)
    return(<div></div>);
    if (props.login_data != undefined && disable_save!= (metaData===props.login_data.meta_data)){
        setDisableSave(metaData===props.login_data.meta_data);
    }
    var license_template=undefined;
    if ( props.login_data.license_templates !== undefined && props.login_data.license_templates[metaData.license]!= undefined){
        license_template = props.login_data.license_templates[metaData.license];
        license_template = license_template.replace("{{ organization }}", metaData.organisation_name||"");
        license_template = license_template.replace("{{ year }}", (new Date).getFullYear());
        license_template = license_template.replace("{{ project }}", metaData.project_name||"");

    }

    return (
    <Grid container justifyContent="center" spacing={2} className={classes.admin}>
        <Grid item xs={12} sm={10} md={10} lg={10}>
            <Paper className={classes.Liscense}>
                <Grid container spacing={2} alignItems="center" alignContent="space-between" justifyContent="space-between" className={classes.header}>
                    <Grid item xs={7}>
                        <h2>About This Page</h2>
                    </Grid>
                    <Grid item xs={5} style={{textAlign: "right"}}>
                        <Button onClick={saveChanges} size="small" variant="outlined" color='secondary' disabled={disable_save}>Save All Changes</Button>
                    </Grid>
                </Grid>
                <Typography> 
                GPPC<sup>2</sup> competition aims to promote and grow research in the area of Path Planning.<br></br>
                Together with the Annual Report, the submissions to GPPC<sup>2</sup> will be open sourced and be public accessible.<br></br>
                A short implementation/submission description and a selected open sourse license will be attached to your sourse code when released to public. 
                </Typography>
            </Paper>
        </Grid>
        <Grid item xs={12} sm={10} md={10} lg={10}>
            <Paper className={classes.Liscense}>
                <h2>Team Description</h2>
                <Typography>
                    The information regarding your team and submissions. 
                </Typography>


                <Table size="small" >
                        <TableBody>

                        <TableRow>
                            <TableCell className={classes.cell}>Team Name* </TableCell>
                            <TableCell className={classes.cell}>
                            <Input
                                color={"secondary"}
                                value = {metaData.team_name|| ''}
                                onChange={(event)=>{handleChange("team_name",event.target.value)}}
                            >
                            </Input>
                            </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell className={classes.cell}>Team members </TableCell>
                            <TableCell className={classes.cell}>
                                <Input
                                    color={"secondary"}
                                    value = {metaData.members|| ''}
                                    onChange={(event)=>{handleChange("members",event.target.value)}}
                                >
                                </Input>
                            </TableCell>
                        </TableRow>

                        </TableBody>
                    </Table>
                

                

                <Typography>
                    Short description to your submissions/algorithms:
                </Typography>
                <TextField 
                className={classes.description} id="outlined-basic" 
                label="Tell us about your submissions*" 
                variant="outlined" 
                value={metaData.description||''} multiline={true}
                onChange={(event)=>{handleChange("description",event.target.value)}}
                />

            </Paper>
        </Grid>

        <Grid item xs={12} sm={10} md={10} lg={10}>
            <Paper className={classes.Liscense}>
                <h2>Contacts</h2>
                <Typography>We will reach you through the provided email when necessary.</Typography>
                
                <Table size="small" >
                        <TableBody>
                            <TableRow>
                                <TableCell className={classes.cell}>Contact Email:* </TableCell>
                                <TableCell className={classes.cell}>
                                    <Input
                                        color={"secondary"}
                                        value = {metaData.email|| ''}
                                        onChange={(event)=>{handleChange("email",event.target.value)}}
                                    >
                                    </Input>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.cell}>Affiliation: </TableCell>
                                <TableCell className={classes.cell}>
                                    <Input
                                        color={"secondary"}
                                        value = {metaData.affiliation|| ''}
                                        onChange={(event)=>{handleChange("affiliation",event.target.value)}}
                                    >
                                    </Input>
                                </TableCell>
                                
                            </TableRow>

                            <TableRow>
                                <TableCell className={classes.cell}>Country: </TableCell>
                                <TableCell className={classes.cell}>
                                    <Input
                                        color={"secondary"}
                                        value = {metaData.country|| ''}
                                        onChange={(event)=>{handleChange("country",event.target.value)}}

                                    >
                                    </Input>
                                </TableCell>
                                
                            </TableRow>
                        </TableBody>
                </Table>

            </Paper>
        </Grid>

        <Grid item xs={12} sm={10} md={10} lg={10}>
            <Paper className={classes.Liscense}>
                <h2>License</h2>
                <Typography>
                Select an open source license here:
                </Typography>
                <Table size="small" >
                    <TableBody>
                    <TableRow>
                            <TableCell className={classes.cell} >Select License:* </TableCell>
                            <TableCell className={classes.cell} >
                                <Select
                                native
                                color={"secondary"}
                                value={metaData.license}
                                onChange={(event)=>{handleChange("license",event.target.value)}}
                                >
                                {Object.keys(props.login_data.license_options).map(function(key, index) {
                                    return(<option key={index} value={key}>{key}</option>)
                                })}
                                </Select>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cell}>Organisation/Author Name: </TableCell>
                            <TableCell className={classes.cell}>
                                <Input
                                    color={"secondary"}
                                    value = {metaData.organisation_name|| ''}
                                    onChange={(event)=>{handleChange("organisation_name",event.target.value)}}
                                >
                                </Input>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cell}>Project Name: </TableCell>
                            <TableCell className={classes.cell}>
                                <Input
                                    color={"secondary"}
                                    value = {metaData.project_name|| ''}
                                    onChange={(event)=>{handleChange("project_name",event.target.value)}}
                                >
                                </Input>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className={classes.cell}>
                                License Preview:
                             </TableCell>
                            <TableCell className={classes.cell}>{license_template}</TableCell>
                        </TableRow>

                    </TableBody>
                </Table>

                

            </Paper>
        </Grid>

        
        
        {/* <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper className={classes.content}>
            
            </Paper>
        </Grid> */}
    </Grid>
    )
    
    
}