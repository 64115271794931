import React from "react";
import { Paper,Grid, Button } from "@material-ui/core";
import Account from "./Account";
import LeaderBoard from "./LeaderBoard";
import { withStyles } from "@material-ui/core/styles";

const useStyles = theme => ({
    my_head: {
    //   textAlign: 'center',
      color: theme.palette.text.primary,
    //   fontSize: 25,
      padding:theme.spacing(2)
    },
    introHead:{
        textAlign: 'center',
        width: "100%",

    },
    buttons:{
        width: "100%",
        textAlign: 'center',
    },
    introButton:{
        textAlign: 'center',
        // width: "100%",
        margin: theme.spacing(0.2)

    },
    intro:{

    },
    competition:{
        width:"100%",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        margin:0,
        paddingTop: theme.spacing(2),
    }
  });

class Competition extends React.Component {

  
    constructor(props) {
      super(props);
    }

    grid_intro(){
        const { classes, track } = this.props;

        return(
        <div>
            <div className={classes.introHead}>
                <h2>Classic Track</h2>
            </div>
            <p>
                Grid is one of the most popular domain in path finding problems. 
            </p>
            <p>
                Read the submission instructions and using the start kit to submit your first entry:
            </p>
            <div className={classes.buttons}>
                <Button className={classes.introButton} size="small" variant="outlined" color="secondary" href="https://github.com/gppc-dev/startkit-classic/tree/master">
                    Start Kit
                </Button>
                <Button className={classes.introButton} size="small" variant="outlined" color="secondary" href="https://github.com/gppc-dev/startkit-classic/blob/master/Problem_Definition.md">
                    Problem Definition
                </Button>
                <Button className={classes.introButton} size="small" variant="outlined" color="secondary" href="https://github.com/gppc-dev/startkit-classic/blob/master/Submission_Instruction.md">
                    Submission Instruction
                </Button>
            </div>

        </div>)
    }

    anyangle_intro(){
        const { classes, track } = this.props;

        return(
        <div>
            <div className={classes.introHead}>
                <h2>Anyangle Track</h2>
            </div>
            <p>
                Any-angle pathfinding, another challenge path finding problem requires finding a Euclidean shortest path between a pair of points in a grid map.
            </p>
            <p>
                Read the submission instructions and using the start kit to submit your first entry:
            </p>
            <div className={classes.buttons}>
                <Button className={classes.introButton} size="small" variant="outlined" color="secondary" href="https://github.com/gppc-dev/startkit-anyangle/tree/master">
                    Start Kit
                </Button>
                <Button className={classes.introButton} size="small" variant="outlined" color="secondary" href="https://github.com/gppc-dev/startkit-anyangle/blob/master/Problem_Definition.md">
                    Problem Definition
                </Button>
                <Button className={classes.introButton} size="small" variant="outlined" color="secondary" href="https://github.com/gppc-dev/startkit-anyangle/blob/master/Submission_Instruction.md">
                    Submission Instruction
                </Button>
            </div>
        </div>)
    }

    render(){
        const { classes, track } = this.props;

        return (
        <Grid container justifyContent="center" spacing={2} className={classes.competition}>
            <Grid item xs={12} sm={11} md={4} lg={3}>
                <Paper className={classes.my_head}>{track === "ANYANGLE"?this.anyangle_intro():this.grid_intro()}</Paper>
                <Account  key={this.props.track} track={this.props.track}  set_logout={this.props.set_logout} login_data={this.props.login_data}></Account>
            </Grid> 
            <Grid item xs={12} sm={11} md={8} lg={8}>
                <LeaderBoard key={this.props.track} >{this.props.track}</LeaderBoard>
            </Grid> 
        </Grid>
        )
    }
}

export default withStyles(useStyles) (Competition);